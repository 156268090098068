import * as React from "react"
import Talks from './talks'
import Sites from './sites'

const Projects = () => {

  return (
    <>
      <Sites />
      <Talks />
    </>
  )
}

export default Projects
